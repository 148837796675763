/**
 * Export `colors`.
 */

export const colors = {
  black: '#000000',
  blue100: '#ebf8fe',
  blue200: '#cfe9f6',
  blue300: '#91c9e5',
  blue400: '#61b0d8',
  blue500: '#0580bd',
  blue600: '#066c9f',
  blue700: '#065881',
  blue900: '#072839',
  green200: '#e5f6e5',
  green300: '#c6e8c6',
  green400: '#6bbd6b',
  green500: '#2ba12b',
  grey100: '#f6f7fa',
  grey200: '#e6e9f2',
  grey300: '#d0d4e3',
  grey400: '#b2b9d1',
  grey500: '#9da7c4',
  grey600: '#868fa8',
  grey700: '#6e768c',
  grey800: '#545b6c',
  grey900: '#21252d',
  orange100: '#fff5e9',
  orange400: '#f8bf73',
  orange600: '#d18c2e',
  purple500: '#a14fbc',
  red100: '#feeeee',
  red300: '#f69a9a',
  red500: '#f05757',
  red600: '#eb1a1a',
  turquoise100: '#c4e0e3',
  turquoise300: '#72b8be',
  turquoise600: '#026874',
  white: '#ffffff'
};
