/**
 * Export survey status colors constants.
 */

import { ApiSubmission, Submission } from 'src/types/submission';
import { QuestionExpectedScore } from 'src/types/question';

/**
 * Export `surveyStatusColors`.
 */

export const surveyStatusColors = {
  completed: 'green',
  ongoing: 'blue',
  rejected: 'green',
  submitted: 'green',
  toAnswer: 'red',
  uncompleted: 'blue'
} as const;

/**
 * Export `getSurveyStatus` util.
 */

export function getSurveyStatus({
  current,
  total
}: Record<'current' | 'total', number>) {
  if (current === 0) {
    return 'toAnswer';
  }

  return current !== total ? 'uncompleted' : 'completed';
}

/**
 * Export `getStatusFromSurvey` util.
 */

export function getStatusFromSurvey(
  survey: ApiSubmission
): Submission['status'] {
  if (survey.isRejected) {
    return 'rejected';
  }

  if (survey.submittedAt) {
    return 'submitted';
  }

  return 'ongoing';
}

/**
 * Export `getMaxScoreQuestions` util.
 */

export function getMaxScoreExpectedByCategory(
  expectedScores = [] as QuestionExpectedScore[]
) {
  return (
    expectedScores?.reduce((acc, { category, expectedScore }) => {
      if (!acc[category] || acc[category] < Number(expectedScore)) {
        acc[category] = Number(expectedScore);
      }

      return acc;
    }, {} as Record<string, number>) ?? {}
  );
}

/**
 * Export `getMaxScoreExpectedByMaturity` util.
 */

export function getMaxScoreExpectedByMaturity(
  expectedScores = [] as QuestionExpectedScore[]
) {
  return (
    expectedScores?.reduce((acc, { expectedScore, maturity }) => {
      if (!acc[maturity]) {
        acc[maturity] = Number(expectedScore);

        return acc;
      }

      acc[maturity] += Number(expectedScore);

      return acc;
    }, {} as Record<string, number>) ?? {}
  );
}

/**
 * Export `getMaxScoreQuestions` util.
 */

export function getMaxScoreExpected(
  expectedScores = [] as QuestionExpectedScore[]
) {
  return Object.values(getMaxScoreExpectedByCategory(expectedScores)).reduce(
    (acc, item) => acc + item,
    0
  );
}
