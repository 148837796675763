/**
 * Module dependencies.
 */

import { BackLink, StickyContent, stickyId } from './sticky';
import { Container } from 'src/components/core/container';
import { Footer } from './footer';
import { Navbar } from './navbar';
import { RawHtml } from '@untile/react-core/components/raw-html';
import { ReactNode } from 'react';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { ifProp } from 'styled-tools';
import { media } from '@untile/react-core/styles/media';
import { routes } from 'src/core/constants/routes';
import { textStyles } from 'src/styles/typography';
import { useRouter } from 'next/router';
import { useSetBodyBackground } from 'src/hooks/use-set-body-background';
import { useTranslation } from 'next-i18next';
import arrowLeftSvg from 'src/assets/24/circle-left.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * Menu routes.
 */

const menuRoutes = ['profile', 'evolutionaryData', 'email', 'password'].map(
  key => ({
    label: `${key}.title`,
    url: routes.settings[key as keyof typeof routes.settings]
  })
);

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 var(--gutter);

  ${media.min.md`
    display: grid;
    grid-template-areas:
      '. . . .'
      'sticky sticky sticky sticky'
      '. . . .'
      '. menu content .'
      'footer footer footer footer';
    grid-template-columns: 1fr 7fr 10fr 6fr;
    grid-template-rows: 0 var(--navbar-height) 64px 1fr minmax(min-content, 48px);
  `}

  ${media.min.lg`
    grid-template-columns: 1fr 7fr 8fr 8fr;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  flex: 1;
  grid-area: content;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  variant: 'display3'
})`
  display: block;
  font-weight: 800;
  margin-bottom: 32px;
  max-width: 72%;
  padding-top: 32px;
  text-transform: uppercase;

  ${media.min.md`
    margin-bottom: 48px;
    padding-top: 0;
  `}
`;

/**
 * `HorizontalScroll` styled component.
 */

const HorizontalScroll = styled.div`
  overflow-x: auto;
  width: calc(100% + 32px);
`;

/**
 * `StyledStickyContent` styled component.
 */

const StyledStickyContent = styled(StickyContent)`
  top: 0;

  ${media.max.md`
    padding-left: 0;
  `}
`;

/**
 * `MenuList` styled component.
 */

const MenuList = styled.ul`
  width: fit-content;

  ${media.max.md`
    display: flex;
    grid-gap: 16px;
    overflow-x: scroll;
    padding: 16px 32px 16px 0;
    white-space: nowrap;
    width: 100%;
  `}

  li {
    display: flex;
    margin-bottom: 8px;
  }
`;

/**
 * `MenuLink` styled component.
 */

const MenuLink = styled(RouterLink)<{ active: boolean }>`
  ${textStyles.paragraph2}

  color: var(--color-grey600);
  font-weight: 700;
  position: relative;

  &::before {
    background-color: var(--color-grey400);
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: var(--transition-default);
    transition-property: background-color, transform;
    width: 100%;
  }

  ${ifProp(
    'active',
    `
    color: var(--color-grey800);

    &::before {
      background-color: var(--color-blue500);
      transform: scaleX(1);
    }
  `,
    `
    :hover,
    :focus {
      &::before {
        transform: scaleX(1);
      }
    }
  `
  )}
`;

/**
 * `Sticky` styled component.
 */

const Sticky = styled.div`
  position: sticky;
  top: calc(var(--navbar-height) + 64px);
`;

/**
 * `SettingsLayout` component.
 */

export function SettingsLayout({ children }: Props) {
  const { t } = useTranslation();
  const router = useRouter();

  useSetBodyBackground('white');

  return (
    <>
      <Navbar />

      <Container>
        <Grid>
          <StyledStickyContent>
            <BackLink href={routes.dashboard}>
              <Svg icon={arrowLeftSvg} size={'24px'} />

              {t('settings:back')}
            </BackLink>

            <div id={stickyId} />
          </StyledStickyContent>

          <aside style={{ gridArea: 'menu' }}>
            <Sticky>
              <Title>
                <RawHtml>{t('settings:title')}</RawHtml>
              </Title>

              <HorizontalScroll>
                <MenuList>
                  {menuRoutes.map(({ label, url }) => (
                    <li key={url}>
                      <MenuLink active={router.asPath === url} href={url}>
                        {t(`settings:${label}`)}
                      </MenuLink>
                    </li>
                  ))}
                </MenuList>
              </HorizontalScroll>
            </Sticky>
          </aside>

          <Content>{children}</Content>

          <Footer />
        </Grid>
      </Container>
    </>
  );
}
