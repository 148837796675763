/**
 * Module dependencies.
 */

import { ReactNode, useEffect, useState } from 'react';
import { RouterLink } from 'src/components/core/links/router-link';
import { createPortal } from 'react-dom';
import { media } from '@untile/react-core/styles/media';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * `StickyLayoutRightActionPortalProps` type.
 */

type StickyLayoutRightActionPortalProps = {
  children: ReactNode;
};

/**
 * Export `stickyId` constant.
 */

export const stickyId = 'stickybar-right-action';

/**
 * Export `StickyContent` styled component.
 */

export const StickyContent = styled.div`
  padding: var(--gutter);

  ${media.min.ms`
    padding: var(--gutter) 0;
  `}

  ${media.min.md`
    align-items: center;
    display: flex;
    grid-column: 1 / -1;
    grid-row: 1 / span 1;
    justify-content: space-between;
    position: sticky;
    top: var(--navbar-total-height);
    width: 100%;
    z-index: 2;
 `}
`;

/**
 * Export `BackLink` styled component.
 */

export const BackLink = styled(RouterLink)`
  ${textStyles.paragraph3}

  align-items: center;
  color: var(--color-grey800);
  display: flex;
  font-weight: 700;
  grid-gap: 8px;
`;

/**
 * Export `StickyLayoutRightActionPortal` component.
 */

export function StickyLayoutRightActionPortal({
  children
}: StickyLayoutRightActionPortalProps): null | React.ReactPortal {
  const [container, setContainer] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setContainer(document.getElementById(stickyId));
  }, []);

  return container ? createPortal(children, container) : null;
}
