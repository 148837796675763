/**
 * Module dependencies.
 */

import { ReactNode, useEffect, useState } from 'react';
import { Spinner } from 'src/components/core/spinner';
import { ifProp } from 'styled-tools';
import { useBodyScroll } from '@untile/react-core/hooks/use-body-scroll';
import { useRouter } from 'next/router';
import styled from 'styled-components';

/**
 * `Animation` type.
 */

type Animation = 'start' | 'end';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ invisible: boolean }>`
  opacity: ${ifProp('invisible', 0, 1)};
  transition: opacity var(--transition-default);
`;

/**
 * `LoadingWrapper` styled component.
 */

const LoadingWrapper = styled.div`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--z-index-loading);
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Spinner).attrs({
  size: 'large'
})`
  color: var(--color-blue400);
`;

/**
 * `PageTransition` component.
 */

export function PageTransition({ children }: Props) {
  const [animation, setAnimation] = useState<Animation>();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = ({ shallow }: any) => {
      if (shallow) {
        return;
      }

      setAnimation('start');
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events, router.pathname]);

  useEffect(() => {
    function handleRouteChangeComplete() {
      setAnimation('end');
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events]);

  useBodyScroll({ off: animation === 'start' });

  return (
    <>
      <Wrapper invisible={animation === 'start'}>{children}</Wrapper>

      {animation === 'start' && (
        <LoadingWrapper>
          <StyledLoading isActive />
        </LoadingWrapper>
      )}
    </>
  );
}
