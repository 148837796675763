/**
 * Module dependencies.
 */

import { axiosInstance, getApiEndpoint } from 'src/core/utils/requests';
import { routes } from 'src/core/constants/routes';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';

/**
 * Export `logout` request.
 */

export async function logout() {
  try {
    const response = await axiosInstance.post(getApiEndpoint('revokeToken'));

    return response?.data;
  } catch (error) {
    return;
  }
}

/**
 * `LogoutOptions` type.
 */

type LogoutOptions = {
  expired?: boolean;
};

/**
 * Export `useLogout` hook.
 */

export function useLogout(options?: LogoutOptions) {
  const router = useRouter();

  return useMutation(logout, {
    onSuccess: () => {
      router.push(
        `${routes.authentication.signIn}${
          options?.expired ? '?session=expired' : ''
        }`
      );
    }
  });
}
