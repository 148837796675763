/**
 * Module dependencies.
 */

import { useEffect } from 'react';

/**
 * Export `useSetBodyBackground` hook.
 */

export function useSetBodyBackground(backgroundColor: string) {
  useEffect(() => {
    if (window?.document?.body?.style) {
      window.document.body.style.backgroundColor = backgroundColor;
    }

    return () => {
      window.document.body.style.backgroundColor =
        'var(--body-background-color)';
    };
  }, [backgroundColor]);
}
