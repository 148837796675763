/**
 * Module dependencies.
 */

import { Container } from 'src/components/core/container';
import { Footer } from './footer';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { ifNotProp, ifProp } from 'styled-tools';
import { media } from '@untile/react-core/styles/media';
import { routes } from 'src/core/constants/routes';
import { useRouter } from 'next/router';
import { useSetBodyBackground } from 'src/hooks/use-set-body-background';
import { useTranslation } from 'next-i18next';
import React, { ReactNode, useMemo } from 'react';
import brpLogoSvg from 'src/assets/brp-logo.svg';
import scoringSvg from 'src/assets/scoring.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode;
  className?: string;
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: white;
  padding: 0 var(--gutter);
  position: relative;

  ${media.max.md`
    background-color: var(--color-blue100);
    background-image: url('/images/logo-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-column-gap: var(--grid-column-gap);
  grid-template-areas: 'intro . content .';
  grid-template-columns: 13fr 1fr 9fr 1fr;
  min-height: 100vh;

  ${media.max.md`
    grid-row-gap: 32px;
    grid-template-areas: 'logoLink' 'content';
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
    padding: 32px 0 var(--gutter);
  `}
`;

/**
 * `Intro` styled component.
 */

const Intro = styled.div`
  align-items: center;
  aspect-ratio: 16/9;
  display: flex;
  grid-area: intro;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  position: sticky;
  top: 0;
  width: calc(100% - 100% / 13);

  video {
    object-fit: cover;
    width: 100%;
  }

  ${media.max.md`
    display: none;
  `}
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  color: var(--color-blue500);
  grid-area: logoLink;
  justify-self: center;

  ${media.min.md`
    display: none;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div<{ showSignInLink: boolean }>`
  color: var(--color-grey700);
  display: grid;
  grid-area: content;
  height: 100%;
  padding: var(--gutter) 0;

  ${ifProp(
    'showSignInLink',
    `
    grid-template-areas: 'signInLink' 'logo' 'description' 'children' 'footer';
    grid-template-rows: repeat(3, min-content) 1fr min-content;
  `,
    `
    grid-template-areas: 'logo' 'description' 'children' 'footer';
    grid-template-rows: repeat(2, min-content) 1fr min-content;
  `
  )}

  ${media.max.md`
    background-color: var(--color-white);
    border-radius: 16px;
    grid-template-columns: 1fr;

    ${ifProp(
      'showSignInLink',
      `
      grid-template-areas: 'signInLink' 'children' 'footer';
      grid-template-rows: max-content 1fr max-content;
    `,
      `
      grid-template-areas: 'children' 'footer';
      grid-template-rows: 1fr max-content;
    `
    )}
  `}
`;

/**
 * `Logo` styled component.
 */

const Logo = styled(Svg).attrs({ size: '163px' })<{ showSignInLink: boolean }>`
  grid-area: logo;
  padding-top: 48px;

  ${ifNotProp(
    'showSignInLink',
    `
    margin-bottom: 16px;
  `
  )}

  ${media.max.md`
    display: none;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({
  as: 'p',
  variant: 'h2'
})`
  grid-area: description;

  ${media.max.md`
    display: none;
  `}
`;

/**
 * `Children` styled component.
 */

const Children = styled.div`
  align-self: flex-start;
  grid-area: children;
  padding: var(--gutter);

  ${media.min.md`
    align-self: center;
    padding: calc(1.25 * var(--navbar-height)) 0;
  `}
`;

/**
 * `StyledFooter` styled component.
 */

const StyledFooter = styled(Footer)`
  grid-area: footer;
`;

/**
 * `SignInLink` styled component.
 */

const SignInLink = styled(RouterLink)`
  color: var(--color-blue600);
  font-weight: 700;
  grid-area: signInLink;
  margin-top: 16px;
  padding: 0 var(--gutter);
  transition: color var(--transition-default);
  width: max-content;

  :focus,
  :focus-within,
  :hover {
    color: var(--color-blue900);
  }

  ${media.min.md`
    justify-self: flex-end;
    margin-top: 48px
    padding: 0;
  `}
`;

/**
 * Export `AuthenticationLayout` component.
 */

export const AuthenticationLayout = ({ children, className }: Props) => {
  const { pathname } = useRouter();
  const { t } = useTranslation();
  const showSignInLink = useMemo(() => {
    return [
      routes.authentication.recoverPassword,
      routes.authentication.resetPassword
    ].includes(pathname as any);
  }, [pathname]);

  useSetBodyBackground('white');

  return (
    <Section>
      <Container>
        <Grid>
          <Intro>
            <video autoPlay muted>
              <source src={'/videos/metamorfose.mp4'} type={'video/mp4'} />
              {'Seu navegador não suporta a tag video.'}
            </video>
          </Intro>

          <LogoLink href={routes.authentication.signIn}>
            <Svg icon={brpLogoSvg} size={'122px'} />
          </LogoLink>

          <Content showSignInLink={showSignInLink}>
            {showSignInLink && (
              <SignInLink href={routes.authentication.signIn}>
                {t('auth:labels.signIn')}
              </SignInLink>
            )}

            <Logo icon={scoringSvg} showSignInLink={showSignInLink} />

            <Description>{t('auth:description')}</Description>

            <Children className={className}>{children}</Children>

            <StyledFooter />
          </Content>
        </Grid>
      </Container>
    </Section>
  );
};
