/**
 * Module dependencies.
 */

import {
  ExtendedAxiosConfig,
  axiosInstance,
  getApiEndpoint
} from 'src/core/utils/requests';

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { User } from 'src/types/user';
import { queryKeys } from 'src/core/constants/query-keys';

/**
 * Export `getMe` request.
 */

export async function getMe(options?: ExtendedAxiosConfig): Promise<User> {
  const response = await axiosInstance.get(getApiEndpoint('getMe'), options);

  return response?.data as User;
}

/**
 * Export `useMe` hook.
 */

export function useMe(options?: UseQueryOptions<User, any, User, any>) {
  return useQuery(queryKeys.me, () => getMe(), options);
}
