/**
 * Module dependencies.
 */

import 'react-toastify/dist/ReactToastify.css';
import { ReactNode, isValidElement } from 'react';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import {
  ToastContainer,
  ToastOptions,
  cssTransition,
  toast as reactToast
} from 'react-toastify';

import { createGlobalStyle } from 'styled-components';
import errorSvg from 'src/assets/16/circle-delete.svg';
import successSvg from 'src/assets/16/circle-check.svg';

/**
 * `transition` constant.
 */

const transition = cssTransition({
  enter: 'toast-animation-fadeInDown',
  exit: 'toast-animation-fadeOutUp'
});

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode;
};

/**
 * `Options` type.
 */

type Options = ToastOptions | undefined;

/**
 * `Message` type.
 */

type Message = {
  description?: string | ReactNode;
  title: string;
};

/**
 * Toast global styles.
 */

const ToastGlobalStyles = createGlobalStyle`
  :root {
    /* Toast */
    --toastify-font-family: var(--font-family-montserrat);
    --toastify-toast-width: clamp(320px, 40vw, 406px);
    --toastify-z-index: var(--z-index-toast);

    --toastify-color-success: var(--alert-success-bg-color);
    --toastify-icon-color-success: var(--alert-success-text-color);
    --toastify-text-color-success: var(--alert-success-text-color);

    --toastify-color-error: var(--alert-error-bg-color);
    --toastify-icon-color-error: var(--alert-error-text-color);
    --toastify-text-color-error: var(--alert-error-text-color);
  }

  .toast-animation-fadeInDown {
    animation: fadeInDown 0.25s ease-out;
    animation-fill-mode: both;
  }

  .toast-animation-fadeOutUp {
    animation: fadeOutUp 0.25s ease-in;
    animation-fill-mode: both;
  }

  .Toastify__toast {
    border-radius: 8px;
    box-shadow: var(--box-shadow-default);
    padding: 0;

    &-body {
      align-items: flex-start;
      padding: 22px 20px;
      white-space: pre-line;
      word-break: break-word;
    }

    &-icon {
      margin-inline-end: 12px;
      position: relative;
      top: 1px;
      width: 16px;
    }

    ${['error', 'success'].map(
      key => `
        &--${key} {
          color: var(--toastify-text-color-${key});
          background-color: var(--toastify-color-${key});
        }
      `
    )}
  }
`;

/**
 * `MessageContent` component.
 */

const MessageContent = (props: Message) => {
  const { description, title } = props;

  return (
    <div style={{ display: 'grid' }}>
      <Text fontWeight={700} variant={'paragraph3'}>
        {title}
      </Text>

      {description && (
        <Text
          style={{ color: 'var(--text-color)', paddingTop: 4 }}
          variant={'paragraph3'}
        >
          {description}
        </Text>
      )}
    </div>
  );
};

/**
 * Alert Icons.
 */

const alertIcons = {
  error: errorSvg,
  success: successSvg
} as const;

/**
 * Default options.
 */

const defaultOptions = {
  icon: ({ type }: { type: keyof typeof alertIcons }): any => (
    <Svg icon={alertIcons?.[type]} size={'16px'} />
  )
};

/**
 * Export `toast` util.
 */

export const toast = {
  error: (message: Message, options?: Options) => {
    reactToast.error(
      isValidElement(message) ? message : <MessageContent {...message} />,
      { ...defaultOptions, ...options } as any
    );
  },
  success: (message: Message, options?: Options) => {
    reactToast.success(
      isValidElement(message) ? message : <MessageContent {...message} />,
      { ...defaultOptions, ...options } as any
    );
  }
} as const;

/**
 * Export `ToastProvider` component.
 */

export function ToastProvider({ children }: Props) {
  return (
    <>
      {children}

      <ToastGlobalStyles />

      <ToastContainer
        autoClose={4000}
        closeButton={false}
        draggable={false}
        hideProgressBar
        limit={4}
        newestOnTop
        position={reactToast.POSITION.TOP_CENTER}
        transition={transition}
      />
    </>
  );
}
