/**
 * Module dependencies.
 */

import { Container } from 'src/components/core/container';
import { Footer } from './footer';
import { Navbar } from './navbar';
import { ReactNode } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  overflow: hidden;
  padding: 0 var(--gutter);
`;

/**
 * Export `BaseLayout` component.
 */

export const BaseLayout = ({ children }: Props) => (
  <>
    <Navbar />

    <Wrapper>
      <Container>{children}</Container>

      <Footer />
    </Wrapper>
  </>
);
