/* eslint-disable new-cap */

/**
 * Module dependencies.
 */

import { Montserrat } from 'next/font/google';
import { colors } from './colors';
import { createCssVariables } from './utils/mixins';
import { css } from 'styled-components';
import { media } from '@untile/react-core/styles/media';

/**
 * Export `montserratFont` configuration.
 */

export const montserratFont = Montserrat({
  style: 'normal',
  subsets: ['latin'],
  weight: ['400', '600', '700', '800']
});

/**
 * Export `containerMaxWidth`.
 */

export const containerMaxWidth = 1920;

/**
 * Export `rootVariables`.
 */

export const rootVariables = css`
  :root {
    /* Colors */
    ${createCssVariables(colors, 'color')}

    --body-background-color: var(--color-grey100);
    --input-error-color: var(--color-red500);
    --primary-color: var(--color-blue500);
    --text-color: var(--color-grey900);

    /* Alert */
    --alert-error-bg-color: var(--color-red100);
    --alert-error-text-color: var(--color-red500);
    --alert-info-bg-color: var(--color-blue100);
    --alert-info-text-color: var(--color-blue500);
    --alert-warning-bg-color: var(--color-orange100);
    --alert-warning-text-color: var(--color-orange600);
    --alert-success-bg-color: var(--color-green300);
    --alert-success-text-color: var(--color-green500);

    /* Fonts */
    --font-family-montserrat: ${montserratFont.style.fontFamily};
    --default-font-size: 16px;
    --default-line-height: 24px;

    /* Forms */
    --form-field-background-color: var(--color-white);
    --form-field-border-color: var(--color-grey400);
    --form-field-border-radius: 8px;
    --form-field-label-color: var(--color-grey600);
    --form-field-label-font-size: 16px;
    --form-field-label-top: 16px;
    --form-field-min-height: 64px;
    --form-field-padding-h: 16px;
    --form-field-padding-v: 8px;
    --form-field-placeholder-color: var(--color-grey600);

    --checkbox-size: 20px;
    --checkbox-column-gap: 14px;
    --radio-size: 20px;
    --radio-column-gap: 10px;

    /* Grid */
    --grid-column-gap: 16px;
    --gutter: 16px;

    ${media.min.ms`
      --gutter: 32px;
    `}

    /* Navbar */
    --navbar-height: 76px;
    --navbar-total-height: var(--navbar-height);

    ${media.min.ms`
      --navbar-height: 96px;
    `}

    /* Container */
    --container-max-width: ${containerMaxWidth}px;

    /* Shadow */
    --box-shadow-default: 0 17px 7px rgba(179, 184, 176, 0.01),
      0 9px 6px rgba(179, 184, 176, 0.05), 0 4px 4px rgba(179, 184, 176, 0.09),
      0 1px 2px rgba(179, 184, 176, 0.1), 0 0 0 rgba(179, 184, 176, 0.1);
    --box-shadow-large: 0 43px 17px rgba(179, 184, 176, 0.01),
      0 24px 14px rgba(179, 184, 176, 0.05),
      0 11px 11px rgba(179, 184, 176, 0.09), 0 3px 6px rgba(179, 184, 176, 0.1),
      0 0 0 rgba(179, 184, 176, 0.1);

    /* Spinner */
    --spinner-delay: 0.9s;

    /* Transitions */
    --transition-animation: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-default: 350ms var(--transition-animation);
    --transition-fast: 150ms var(--transition-animation);
    --transition-slow: 500ms ease-in-out;

    /* Z-index */
    --z-index-dropdown: 10;
    --z-index-modal-underlay: 99;
    --z-index-modal: 100;
    --z-index-navbar: 90;
    --z-index-loading: 100;
    --z-index-toast: 98;
  }
`;
