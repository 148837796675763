/**
 * Module dependencies.
 */

import { createGlobalStyle } from 'styled-components';
import { globalKeyframes } from './keyframes';
import { rootVariables } from './variables';

/**
 * Export `GlobalStyle` component.
 */

export const GlobalStyle = createGlobalStyle`
  ${rootVariables}
  ${globalKeyframes}

  body {
    background-color: var(--body-background-color);
    color: var(--text-color);
    font-family: var(--font-family-montserrat);
    font-size: var(--default-font-size);
    font-style: normal;
    font-weight: 400;
    line-height: var(--default-line-height);
  }
`;
