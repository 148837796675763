/**
 * Module dependencies.
 */

import { ifNotProp, switchProp } from 'styled-tools';
import React, { forwardRef, useEffect, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

/**
 * `LoadingState` type.
 */

type LoadingState = 'active' | 'complete' | null;

/**
 * `Size` type.
 */

type Size = 'large' | 'medium' | 'small';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  isActive?: boolean;
  isRelative?: boolean;
  ref?: React.RefObject<HTMLDivElement> | undefined;
  size?: Size;
};

/**
 * `GlobalStyle` component.
 */

const GlobalStyle = createGlobalStyle`
  @keyframes completeFill {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes startingFill {
    to {
      stroke-dashoffset: 270;
    }
  }

  @keyframes varyLoaderWidth {
    0% {
      stroke-dashoffset: 270;
    }

    50% {
      stroke-dashoffset: 170;
    }

    100% {
      stroke-dashoffset: 275;
    }
  }
`;

/**
 * `SvgCircle` styled component.
 */

const SvgCircle = styled.circle.attrs({
  cx: 50,
  cy: 50,
  r: 44 // eslint-disable-line id-length
})`
  fill: transparent;
  stroke: currentcolor;
  stroke-width: 8px;
`;

/**
 * `LoaderRing` styled component.
 */

const LoaderRing = styled(SvgCircle)<{ state: LoadingState }>`
  stroke-dasharray: 276 276;
  stroke-dashoffset: 276;
  transform-origin: 50px 50px;

  ${switchProp('state', {
    active: css`
      animation: startingFill 0.5s forwards,
        varyLoaderWidth 3s 0.5s linear infinite alternate,
        spin 1.6s 0.2s linear infinite;
    `,
    complete: css`
      animation: startingFill 0.5s forwards,
        varyLoaderWidth 3s 0.5s linear infinite alternate,
        spin 1.6s 0.2s linear infinite, fadeOut 0.1 0.5s linear forwards;
    `
  })}
`;

/**
 * `LoaderRingOverlay` styled component.
 */

const LoaderRingOverlay = styled(SvgCircle)<{ state: LoadingState }>`
  stroke-dasharray: 276 276;
  stroke-dashoffset: 276;
  transform-origin: 50px 50px;

  ${switchProp('state', {
    active: css`
      animation: spin 1.6s 0.2s linear infinite;
      visibility: hidden;
    `,
    complete: css`
      animation: completeFill 0.5s linear forwards,
        spin 1.6s 0.2s linear infinite;
      visibility: visible;
    `
  })}
`;

/**
 * `RingTrack` styled component.
 */

const RingTrack = styled(SvgCircle)`
  stroke-opacity: 0.3;
`;

/**
 * `Svg` styled component.
 */

const Svg = styled.svg<{ state: LoadingState }>`
  animation: fadeOut 0.2s 0.7s linear forwards;
  transition: 0s var(--spinner-delay);
  transition-property: opacity, fill, stroke, stroke-width;

  ${switchProp('state', {
    active: css`
      animation: fadeIn 0.3s linear both;
    `
  })}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span.attrs(({ size }: { size?: Size }) => ({
  size: size ?? 'small'
}))<Pick<Props, 'className' | 'isRelative' | 'size'>>`
  display: inline-block;
  line-height: 0;
  pointer-events: none;
  width: ${switchProp('size', {
    large: '32px',
    medium: '24px',
    small: '16px'
  })};

  ${ifNotProp(
    'isRelative',
    css`
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transform-origin: center;
    `,
    css`
      position: relative;
    `
  )};
`;

/**
 * Export `Spinner` component.
 */

export const Spinner = forwardRef(({ isActive, ...props }: Props, ref: any) => {
  const [state, setState] = useState<LoadingState>(null);

  useEffect(() => {
    if (isActive && state === null) {
      setState('active');
    }

    if (!isActive && state === 'active') {
      setState('complete');

      setTimeout(() => {
        setState(null);
      }, 900);
    }
  }, [isActive, state]);

  return (
    <>
      <GlobalStyle />

      {!!state && (
        <Wrapper {...props} ref={ref}>
          <Svg
            state={state}
            viewBox={'0 0 100 100'}
            xmlns={'http://www.w3.org/2000/svg'}
          >
            <RingTrack />

            <LoaderRing state={state} />

            <LoaderRingOverlay state={state} />
          </Svg>
        </Wrapper>
      )}
    </>
  );
});

/**
 * `Spinner` display name.
 */

Spinner.displayName = 'Spinner';
