/**
 * Module dependencies.
 */

import { Context, ReactNode, createContext, useContext, useMemo } from 'react';
import { User } from 'src/types/user';
import { parseCookies } from 'nookies';
import { useMe } from 'src/api/me/get-me';

/**
 * `Session` type.
 */

type Session = {
  user: User | undefined;
};

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * Export `SessionContext`.
 */

export const SessionContext: Context<Session> = createContext({} as Session);

/**
 * Export `useSession` hook.
 */

export function useSession(): Session {
  return useContext(SessionContext);
}

/**
 * Export `SessionProvider` component.
 */

export function SessionProvider({ children }: Props) {
  const cookies = parseCookies();
  const { data: user } = useMe({ enabled: !!cookies?.token });
  const sessionState = useMemo(() => ({ user }), [user]);

  return (
    <SessionContext.Provider value={sessionState}>
      {children}
    </SessionContext.Provider>
  );
}
