/**
 * Module dependencies.
 */

import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: var(--color-blue200);
  column-gap: 40px;
  display: flex;
  padding: 20px var(--gutter);

  ${media.max.md`
    flex-direction: column;
    align-items: start;
    row-gap: 16px;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  block: true,
  color: 'blue500',
  fontWeight: 700,
  variant: 'paragraph2'
})`
  min-width: max-content;
`;

/**
 * Export `ValidatingBanner` component.
 */

export const ValidatingBanner = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('common:banners.validatingSignUp.title')}</Title>

      <Text block variant={'paragraph2'}>
        <RawHtml>{t('common:banners.validatingSignUp.description')}</RawHtml>
      </Text>
    </Wrapper>
  );
};
