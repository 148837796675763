/**
 * Module dependencies.
 */

import { ifNotProp } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `Container` component.
 */

export const Container = styled.div<{ fluid?: boolean }>`
  position: relative;
  width: 100%;

  ${ifNotProp(
    'fluid',
    css`
      margin-left: auto;
      margin-right: auto;
      max-width: var(--container-max-width);
    `
  )}
`;
