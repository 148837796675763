/**
 * Module dependencies.
 */

import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { ifProp } from 'styled-tools';
import { setLinkProps } from 'src/core/utils/links';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * `LinkProps` type.
 */

type LinkProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

/**
 * `Link` styled component.
 */

export const Link = styled.a.attrs<LinkProps>(setLinkProps)`
  appearance: none;
  transition: color var(--transition-default);

  :hover,
  :focus {
    outline: none;
  }

  ${ifProp(
    'disabled',
    `
    pointer-events: none;
  `
  )}
`;

/**
 * `UnderlineLink` styled component.
 */

export const UnderlineLink = styled.a.attrs<LinkProps>(setLinkProps)`
  ${textStyles.paragraph3}

  appearance: none;
  background: none;
  border: none;
  color: var(--color-blue700);
  text-decoration: underline;
  transition: color var(--transition-default);

  :hover,
  :focus {
    color: var(--color-blue900);
    outline: none;
  }

  ${ifProp(
    'disabled',
    `
    color: var(--color-grey200);
    pointer-events: none;
  `
  )}
`;
