/**
 * Module dependencies.
 */

import { RefObject, useEffect, useRef, useState } from 'react';

/**
 * `ElementDimensionsReturn` type.
 */

type ElementDimensionsReturn<T> = [
  RefObject<T>,
  { height: number; width: number }
];

/**
 * `useElementDimensions` hook.
 */

export function useElementDimensions<
  T extends HTMLElement
>(): ElementDimensionsReturn<T> {
  const ref = useRef<T>(null);
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      const target = ref.current;

      if (!target) {
        return;
      }

      const { height, width } = target.getBoundingClientRect();

      setDimensions({ height, width });
    };

    updateDimensions();

    if (typeof ResizeObserver !== 'function') {
      return;
    }

    const resizeObserver = new ResizeObserver(updateDimensions);

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return [ref, dimensions];
}
