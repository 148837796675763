/**
 * Module dependencies.
 */

import { DefaultSeoProps } from 'next-seo';
import packageJson from 'package.json';

/**
 * Export `seoDefaultConfig`.
 */

export const seoDefaultConfig: DefaultSeoProps = {
  additionalMetaTags: [
    {
      content: packageJson.version,
      name: 'version'
    },
    {
      content: 'Untile',
      name: 'author'
    }
  ],
  defaultTitle: 'BRP',
  openGraph: {
    siteName: 'BRP',
    url: process.env.NEXT_PUBLIC_BASE_URL
  },
  titleTemplate: '%s | BRP'
};
