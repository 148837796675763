/**
 * Export `apiEndpoints`
 */

export const apiEndpoints = {
  answer: '/answer',
  answerAverageScores: '/answer/average-scores',
  completeSignUp: '/users/completeUserRegistration',
  createSubmission: '/submissions',
  duplicateSubmission: '/submissions/duplicate/:id',
  getExpectedScores: 'questions/expected-scores',
  getMe: '/me',
  getQuestions: '/questions',
  getUserSettings: '/users/settings',
  previousSubmissionById: '/submissions/:id/previous',
  recoverPassword: '/auth/password/forgot',
  resetPassword: '/auth/password/reset',
  revokeToken: '/auth/token/revoke',
  signIn: '/auth/login',
  signUp: '/users',
  submissionById: '/submissions/:id',
  submissions: '/submissions',
  submitSubmission: '/submissions/submit/:id',
  updateAnswer: '/answer/:id',
  updateUser: '/me',
  updateUserEmail: '/me/email',
  updateUserPassword: '/auth/password'
} as const;

/**
 * Export `ApiEndpoint` type.
 */

export type ApiEndpoint = keyof typeof apiEndpoints;
