/**
 * Module dependencies.
 */

import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ElementType,
  forwardRef
} from 'react';

import { Spinner } from 'src/components/core/spinner';
import { ifProp, switchProp } from 'styled-tools';
import { setButtonLinkProps } from 'src/core/utils/links';
import styled, { css } from 'styled-components';

/**
 * Export `buttonThemesConfig` constant.
 */

export const buttonThemesConfig = {
  primary: {
    active: {
      backgroundColor: 'var(--color-blue600)',
      borderColor: 'var(--color-blue600)',
      textColor: 'var(--color-white)'
    },
    disabled: {
      backgroundColor: 'var(--color-blue100)',
      borderColor: 'var(--color-blue100)',
      textColor: 'var(--color-blue400)'
    },
    idle: {
      backgroundColor: 'var(--color-blue500)',
      borderColor: 'var(--color-blue500)',
      textColor: 'var(--color-white)'
    }
  },
  secondary: {
    active: {
      backgroundColor: 'var(--color-grey300)',
      borderColor: 'var(--color-grey300)',
      textColor: 'var(--color-grey800)'
    },
    disabled: {
      backgroundColor: 'var(--color-grey100)',
      borderColor: 'var(--color-grey100)',
      textColor: 'var(--color-grey400)'
    },
    idle: {
      backgroundColor: 'var(--color-grey200)',
      borderColor: 'var(--color-grey200)',
      textColor: 'var(--color-grey800)'
    }
  },
  secondaryOutlined: {
    active: {
      backgroundColor: 'var(--color-grey200)',
      borderColor: 'var(--color-grey800)',
      textColor: 'var(--color-grey800)'
    },
    disabled: {
      backgroundColor: 'var(--color-white)',
      borderColor: 'var(--color-grey400)',
      textColor: 'var(--color-grey400)'
    },
    idle: {
      backgroundColor: 'var(--color-white)',
      borderColor: 'var(--color-grey500)',
      textColor: 'var(--color-grey800)'
    }
  }
};

/**
 * Export `buttonSizes` constant.
 */

export const buttonSizes = {
  medium: css`
    --button-height: 62px;
  `,
  small: css`
    --button-height: 38px;
  `
};

/**
 * Export `ButtonProps` type.
 */

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  as?: ElementType;
  colorTheme?: keyof typeof buttonThemesConfig;
  href?: string;
  isLoading?: boolean;
  ref?: React.RefObject<HTMLButtonElement> | undefined;
  rel?: string;
  size?: keyof typeof buttonSizes;
  stretch?: boolean;
  target?: string;
};

/**
 * Export `buttonTheme´ constant.
 */

export const buttonThemes = Object.entries(buttonThemesConfig).reduce(
  (previous, [themeName, config]) => ({
    ...previous,
    [themeName]: `
      --button-background-color: ${config.idle.backgroundColor};
      --button-border-color: ${config.idle.borderColor};
      --button-text-color: ${config.idle.textColor};
      --button-active-background-color: ${config.active.backgroundColor};
      --button-active-border-color: ${config.active.borderColor};
      --button-active-text-color: ${config.active.textColor};
      --button-disabled-background-color: ${config.disabled.backgroundColor};
      --button-disabled-border-color: ${config.disabled.borderColor};
      --button-disabled-text-color: ${config.disabled.textColor};
    `
  }),
  {}
);

/**
 * `Loading` styled component.
 */

const Loading = styled(Spinner)`
  margin-right: 10px;
`;

/**
 * `buttonPropsConfig` styled component.
 */

const buttonPropsConfig = {
  shouldForwardProp: (prop: string | number) =>
    !['colorTheme', 'isLoading', 'size', 'stretch'].includes(prop as string)
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button
  .withConfig(buttonPropsConfig)
  .attrs(setButtonLinkProps)<ButtonProps>`
  ${switchProp('colorTheme', buttonThemes)}
  ${switchProp('size', buttonSizes)}

  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  border-radius: 8px;
  color: var(--button-text-color);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font-family-montserrat);
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.3px;
  min-height: var(--button-height);
  outline: none;
  padding: 8px 16px;
  place-items: center;
  position: relative;
  text-transform: uppercase;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color, opacity;

  &:focus,
  &:hover {
    background-color: var(--button-active-background-color);
    border-color: var(--button-active-border-color);
    color: var(--button-active-text-color);
  }

  ${ifProp('stretch', 'width: 100%;')}
  ${ifProp(
    'disabled',
    `
    background-color: var(--button-disabled-background-color);
    border-color: var(--button-disabled-border-color);
    color: var(--button-disabled-text-color);
    cursor: default;
    pointer-events: none;
  `
  )}
`;

/**
 * `Content` styled component.
 */

const Content = styled.span.withConfig(buttonPropsConfig)<
  Required<Pick<ButtonProps, 'isLoading'>>
>`
  opacity: ${ifProp('isLoading', 0, 1)};
  transition: opacity var(--transition-default)
    ${ifProp('isLoading', '0s', 'var(--spinner-delay)')};
`;

/**
 * Export `Button` component.
 */

export const Button = forwardRef<any, ButtonProps>(
  (props: ButtonProps, ref: any) => {
    const {
      children,
      colorTheme = 'primary',
      disabled,
      isLoading,
      size = 'medium',
      ...rest
    } = props;

    return (
      <Wrapper
        colorTheme={colorTheme}
        disabled={isLoading || disabled}
        isLoading={isLoading}
        ref={ref}
        size={size}
        {...rest}
      >
        <Loading isActive={!!isLoading} size={size} />
        <Content isLoading={!!isLoading}>{children as any}</Content>
      </Wrapper>
    );
  }
);

/**
 * `Button` display name.
 */

Button.displayName = 'Button';
