/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/button';
import { Text } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import { routes } from 'src/core/constants/routes';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  background-color: var(--color-orange400);
  column-gap: 40px;
  display: flex;
  padding: 12px var(--gutter);

  ${media.max.md`
    flex-direction: column;
    align-items: start;
    row-gap: 16px;
  `}
`;

/**
 * Export `CompleteSignUpBanner` component.
 */

export const CompleteSignUpBanner = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Text block fontWeight={700} variant={'paragraph2'}>
        {t('common:banners.pendingSignUp.title')}
      </Text>

      <Text block variant={'paragraph2'}>
        {t('common:banners.pendingSignUp.description')}
      </Text>

      <Button href={routes.completeSignUp} size={'small'}>
        {t('common:banners.pendingSignUp.action')}
      </Button>
    </Wrapper>
  );
};
