/**
 * Module dependencies.
 */

import { BackLink, StickyContent, stickyId } from './sticky';
import { Container } from 'src/components/core/container';
import { Footer } from './footer';
import { Navbar } from './navbar';
import { ReactNode } from 'react';
import { Svg } from '@untile/react-core/components/svg';
import { media } from '@untile/react-core/styles/media';
import { routes } from 'src/core/constants/routes';
import { useTranslation } from 'next-i18next';
import arrowLeftSvg from 'src/assets/24/circle-left.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * Export `Grid` styled component.
 */

export const Grid = styled.div`
  background-color: var(--color-grey100);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 var(--gutter);

  ${media.min.md`
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 96px 1fr minmax(min-content, 48px);
  `}
`;

/**
 * `SurveyLayout` component.
 */

export function SurveyLayout({ children }: Props) {
  const { t } = useTranslation();
  const backTranslationKey =
    (children as any)?.props?.backTranslation ?? 'survey:actions:back';

  return (
    <>
      <Navbar />

      <Container>
        <Grid>
          <StickyContent style={{ backgroundColor: 'var(--color-grey100)' }}>
            <BackLink
              href={(children as any)?.props?.backUrl ?? routes.dashboard}
            >
              <Svg icon={arrowLeftSvg} size={'24px'} />

              {t(backTranslationKey)}
            </BackLink>

            {!(children as any)?.props?.hideLayoutSticky && (
              <div id={stickyId} />
            )}
          </StickyContent>

          {children}

          <div style={{ gridColumn: '1 / -1', gridRow: 5 }}>
            <Footer />
          </div>
        </Grid>
      </Container>
    </>
  );
}
