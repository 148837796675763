/**
 * Export `regexes`.
 */

export const regexes = {
  certificateCode: /^\d{4}-\d{4}-\d{4}$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  interpolateUrl: /:([\s\S]+?)\b/g,
  nif: /^[125689][0-9]{2}\s[0-9]{3}\s[0-9]{3}$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.,-_%+=])[A-Za-z\d@$!%*?&#.,-_%+=]{6,}$/
};
