/**
 * Module dependencies.
 */

import { ApiSubmission, Submission } from 'src/types/submission';
import { AxiosError } from 'axios';
import {
  ExtendedAxiosConfig,
  axiosInstance,
  getApiEndpoint,
  getNetworkErrorTranslatedKey
} from 'src/core/utils/requests';

import { PaginatedData } from 'src/types/paginated';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';

import { getRoute } from 'src/core/constants/routes';
import { getStatusFromSurvey } from 'src/core/utils/surveys';
import { queryKeys } from 'src/core/constants/query-keys';
import { toast } from 'src/providers/toast';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

/**
 * `InfinitePaginatedData` type
 */

type InfinitePaginatedData<TData> = {
  pages: PaginatedData<TData>[];
  params: { pageParam: number | undefined };
};

/**
 * `cacheTime` constant.
 */

const cacheTime = 1000 * 60 * 60;

/**
 * Export `submittedFilter` constant.
 */

export const submittedFilter = { params: { filter: { submitted: 'true' } } };

/**
 * Export `getSubmissionCategoryScores` util.
 */

export function getSubmissionCategoryScores(submission: ApiSubmission) {
  return submission?.statistic?.reduce(
    (acc: Record<string, number>, answer) => ({
      ...acc,
      [answer?.category]:
        (acc?.[answer?.category] ?? 0) + Number(answer.scoreSum)
    }),
    {}
  );
}

/**
 * `normalizeSubmission` util.
 */

function normalizeSubmission(submission: ApiSubmission): Submission {
  const total = submission.statistic.reduce(
    (acc, curr) => ({
      answers: acc.answers + curr.answeredQuestions,
      questions: acc.questions + curr.requiredQuestions,
      score: acc.score + Number(curr.scoreSum)
    }),
    { answers: 0, questions: 0, score: 0 }
  );

  return {
    ...submission,
    score: total.score,
    scoreByCategory: getSubmissionCategoryScores(submission),
    status: getStatusFromSurvey(submission),
    totalAnswers: total.answers,
    totalQuestions: total.questions
  };
}

/**
 * Export `createSubmission` request.
 */

export async function createSubmission(): Promise<Submission> {
  const response = await axiosInstance.post(
    getApiEndpoint('submissions'),
    undefined
  );

  return normalizeSubmission(response.data) as Submission;
}

/**
 * Export `useCreateSubmission` hook.
 */

export function useCreateSubmission(
  options?: UseMutationOptions<Submission, AxiosError, void>
) {
  const { t } = useTranslation();
  const router = useRouter();
  const queryClient = useQueryClient();

  return useMutation(createSubmission, {
    ...options,
    onError: (error: AxiosError, ...args) => {
      options?.onError?.(error, ...args);
      const errorMessage = getNetworkErrorTranslatedKey({
        basePath: 'common:errors.createSurvey.',
        error
      });

      toast.error(t(errorMessage, { returnObjects: true }));
    },
    onSuccess: (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      queryClient.invalidateQueries(queryKeys.submission.infinite);
      router.push(getRoute('survey.ongoing', { id: data.id }));
    }
  });
}

/**
 * Export `submitSubmission` request.
 */

export async function submitSubmission(
  id: string,
  options?: ExtendedAxiosConfig
): Promise<Submission> {
  const response = await axiosInstance.patch(
    getApiEndpoint('submitSubmission', { id }),
    undefined,
    options
  );

  return normalizeSubmission(response.data) as Submission;
}

/**
 * Export `deleteSubmission` request.
 */

export async function deleteSubmission(
  id: string,
  options?: ExtendedAxiosConfig
): Promise<void> {
  const response = await axiosInstance.delete(
    getApiEndpoint('submissionById', { id }),
    options
  );

  return response?.data;
}

/**
 * Export `duplicateSubmission` request.
 */

export async function duplicateSubmission(
  id: string,
  options?: ExtendedAxiosConfig
): Promise<Submission> {
  const response = await axiosInstance.post(
    getApiEndpoint('duplicateSubmission', { id }),
    options
  );

  return normalizeSubmission(response.data) as Submission;
}

/**
 * Export `getSubmissionById` request.
 */

export async function getSubmissionById(
  id: string,
  options?: ExtendedAxiosConfig
): Promise<Submission> {
  const response = await axiosInstance.get(
    getApiEndpoint('submissionById', { id }),
    options
  );

  return normalizeSubmission(response.data);
}

/**
 * Export `getPreviousSubmissionById` request.
 */

export async function getPreviousSubmissionById(
  id: string,
  options?: ExtendedAxiosConfig
): Promise<Submission | undefined> {
  const response = await axiosInstance.get(
    getApiEndpoint('previousSubmissionById', { id }),
    options
  );

  return normalizeSubmission(response.data);
}

/**
 * Export `getSubmissions` request.
 */

export async function getSubmissions(
  options?: ExtendedAxiosConfig
): Promise<PaginatedData<Submission>> {
  const response = await axiosInstance.get(
    getApiEndpoint('submissions'),
    options
  );

  return {
    ...response.data,
    data: response.data.data.map(normalizeSubmission)
  };
}

/**
 * Export `useSubmission` request.
 */

export function useSubmission(
  id: string,
  options?: UseQueryOptions<Submission, any, Submission, any>
) {
  return useQuery(queryKeys.submission.byId(id), () => getSubmissionById(id), {
    cacheTime,
    ...options
  });
}

/**
 * Export `usePreviousSubmission` request.
 */

export function usePreviousSubmission(
  id: string,
  options?: UseQueryOptions<Submission | undefined, any, Submission, any>
) {
  return useQuery(
    queryKeys.submission.previous(id),
    () => getPreviousSubmissionById(id),
    { cacheTime, enabled: !!id, ...options }
  );
}

/**
 * Export `useSubmittedSubmissions` request.
 */

export function useSubmittedSubmissions(
  options?: UseQueryOptions<any, any, PaginatedData<Submission>, any>
) {
  return useQuery(
    queryKeys.submission.submittedList,
    () => getSubmissions(submittedFilter as any),
    { cacheTime, ...options }
  );
}

/**
 * Export `useSubmissions` request.
 */

export function useSubmissions(
  options?: UseInfiniteQueryOptions<any, AxiosError, PaginatedData<Submission>>
) {
  return useInfiniteQuery({
    cacheTime,
    getNextPageParam: (page: PaginatedData<Submission>) => {
      const nextPage = page.currentPage + 1;

      return nextPage <= page.lastPage ? nextPage : undefined;
    },
    queryFn: ({ pageParam }) =>
      getSubmissions({ params: { page: pageParam } } as any),
    queryKey: queryKeys.submission.infinite,
    ...options
  });
}

/**
 * `useSubmitSubmission` hook.
 */

export function useSubmitSubmission(
  options?: UseMutationOptions<Submission, AxiosError, string>
) {
  const { t } = useTranslation(['common', 'survey']);

  return useMutation({
    ...options,
    mutationFn: submitSubmission,
    onError: (error: AxiosError, ...args) => {
      options?.onError?.(error, ...args);

      if (error.response?.status !== 422) {
        const errorMessage = getNetworkErrorTranslatedKey({
          basePath: 'survey:errors.form.',
          error
        });

        toast.error(t(errorMessage, { returnObjects: true }));
      }
    },
    onSuccess: (data, ...args) => {
      options?.onSuccess?.(data, ...args);

      toast.success(
        t('survey:detail.submitSuccess', {
          id: data.submissionNumber,
          returnObjects: true
        })
      );
    }
  });
}

/**
 * `useDeleteSubmission` hook.
 */

export function useDeleteSubmission(
  options?: UseMutationOptions<void, AxiosError, string>
) {
  const { t } = useTranslation(['common', 'survey']);
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: deleteSubmission,
    onError: (error: AxiosError) => {
      const errorMessage = getNetworkErrorTranslatedKey({
        basePath: 'survey:errors.form.',
        error
      });

      toast.error(t(errorMessage, { returnObjects: true }));
    },
    onSuccess: (data, id, context) => {
      const queryKey = queryKeys.submission.infinite;

      queryClient.invalidateQueries([queryKey]);
      queryClient.setQueryData(
        queryKey,
        (oldData?: InfinitePaginatedData<Submission>) => {
          if (!oldData?.pages?.length) {
            return oldData;
          }

          return {
            ...oldData,
            pages: oldData.pages.map(page => ({
              ...page,
              data: page.data.filter(item => item.id !== id)
            }))
          };
        }
      );

      toast.success(
        t('dashboard:survey.deleteSuccess', { returnObjects: true })
      );

      if (options?.onSuccess) {
        options.onSuccess(data, id, context);
      }
    }
  });
}

/**
 * `useDuplicateSubmission` hook.
 */

export function useDuplicateSubmission(
  options?: UseMutationOptions<Submission, AxiosError, string>
) {
  const { t } = useTranslation(['common', 'survey']);
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: duplicateSubmission,
    onError: (error: AxiosError) => {
      const errorMessage = getNetworkErrorTranslatedKey({
        basePath: 'survey:errors.form.',
        error
      });

      toast.error(t(errorMessage, { returnObjects: true }));
    },
    onSuccess: (newSurvey, variables, context) => {
      const queryKey = queryKeys.submission.infinite;

      queryClient.invalidateQueries([queryKey]);
      queryClient.setQueryData(
        queryKey,
        (oldData?: InfinitePaginatedData<Submission>) => {
          if (!oldData?.pages?.length) {
            return oldData;
          }

          return {
            ...oldData,
            pages: oldData.pages.map((page, index) => ({
              ...page,
              data: index === 0 ? [newSurvey, ...page.data] : page.data
            }))
          };
        }
      );

      toast.success(
        t('dashboard:survey.duplicateSuccess', {
          id: newSurvey.submissionNumber,
          returnObjects: true
        })
      );

      if (options?.onSuccess) {
        options.onSuccess(newSurvey, variables, context);
      }
    }
  });
}
