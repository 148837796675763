/**
 * Module dependencies.
 */

import get from 'lodash/get';
import template from 'lodash/template';

/**
 * Export `routes`.
 */

export const routes = {
  authentication: {
    logout: '/logout',
    maintenance: '/manutencao',
    recoverPassword: '/recuperar-palavra-passe',
    resetPassword: '/redefinir-palavra-passe',
    signIn: '/',
    signUp: '/registar'
  },
  completeSignUp: '/completar-registo',
  dashboard: '/dashboard',
  notFound: '/404',
  settings: {
    email: '/definicoes/email/',
    evolutionaryData: '/definicoes/dados-evolutivos/',
    password: '/definicoes/palavra-passe/',
    profile: '/definicoes/perfil-da-empresa/'
  },
  survey: {
    finish: '/questionario/:id/terminar',
    ongoing: '/questionario/:id/em-curso',
    start: '/novo-questionario',
    submitted: '/questionario/:id/submetido',
    survey: '/questionario/:id'
  }
} as const;

/**
 * `ObjectKeys` type.
 */

type ObjectKeys<T extends string> = T extends '' ? '' : `.${T}`;

/**
 * `ObjectKeysLeaves` type.
 */

type ObjectKeysLeaves<T> = (
  T extends object
    ? {
        [K in Exclude<keyof T, symbol>]: `${K}${ObjectKeys<
          ObjectKeysLeaves<T[K]>
        >}`;
      }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never;

/**
 * Export `RouteKey` type.
 */

export type RouteKey = ObjectKeysLeaves<typeof routes>;

/**
 * Export `getRoute`.
 */

export function getRoute(
  routeKey: RouteKey,
  interpolations?: { [key: string]: string | number }
) {
  const route = get(routes, routeKey);

  if (interpolations) {
    return template(route as string, {
      interpolate: /:([\s\S]+?)\b/g
    })(interpolations);
  }

  return route as string;
}
