/**
 * Module dependencies.
 */

import { GetServerSidePropsContext, NextApiResponse } from 'next';
import { apiEndpoints } from 'src/core/constants/api-endpoints';
import { regexes } from 'src/core/constants/regexes';
import { routes } from 'src/core/constants/routes';
import applyCaseMiddleware from 'axios-case-converter';
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';

import camelCase from 'lodash/camelCase';
import qs from 'qs';
import template from 'lodash/template';

/**
 * Export `ExtendedAxiosConfig`.
 */

export type ExtendedAxiosConfig = InternalAxiosRequestConfig & {
  serverApi?: boolean;
};

/**
 * Export `getApiEndpoint`.
 */

export function getApiEndpoint(
  endpointName: keyof typeof apiEndpoints,
  interpolations?: Record<string, string | number>
): string {
  const endpoint = apiEndpoints[endpointName];

  if (interpolations) {
    return template(endpoint, {
      interpolate: regexes.interpolateUrl
    })(interpolations);
  }

  return endpoint;
}

/**
 * Export `handleApiResponseError`.
 */

export function handleApiResponseError(
  response: NextApiResponse,
  error: unknown
) {
  const { data, status } = (error as AxiosError)?.response ?? {};

  if (status) {
    response.status(status).json(data);

    return;
  }

  response.status(500);
}

/**
 * Export `getAuthorizationHeader`.
 */

export function getAuthorizationHeader(token?: string) {
  if (!token) {
    return undefined;
  }

  return {
    Authorization: `Bearer ${token}`
  };
}

/**
 * Export `getAuthHeaderFromNextContext` util.
 */

export function getAuthHeaderFromNextContext(
  context: GetServerSidePropsContext
) {
  const token = context.req.cookies.token;
  const headers = getAuthorizationHeader(token);

  return headers;
}

/**
 * `NetworkErrorOptions` type.
 */

type NetworkErrorOptions = {
  basePath?: string;
  error: AxiosError;
};

/**
 * Export `getNetworkErrorTranslatedKey`.
 *
 * Shape on translations: ${key}.errors.network.${error}.
 * The default ${error} key is `default`;
 */

export function getNetworkErrorTranslatedKey(options: NetworkErrorOptions) {
  const { basePath, error } = options;
  const networkError = error?.response?.statusText;

  return [
    `${basePath}errors.network.${camelCase(networkError)}`,
    `${basePath}errors.network.default`,
    'common:networkErrors.default'
  ];
}

/**
 * Export `axiosInstance`.
 */

export const axiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: '/api',
    paramsSerializer: {
      serialize: params => qs.stringify(params)
    },
    withCredentials: true
  }),
  { ignoreHeaders: true }
);

/**
 * Axios instance request.
 */

axiosInstance.interceptors.request.use(
  (config: ExtendedAxiosConfig) => {
    if (config?.serverApi) {
      // Inject full url for server side requests.
      config.baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;
    }

    if (config.headers['Content-Type'] === 'multipart/form-data') {
      return config;
    }

    return config;
  },
  error => Promise.reject(error)
);

/**
 * Axios instance request.
 */

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (
      error?.response?.status === 401 &&
      // @ts-ignore
      error?.response?.data?.message !== 'Login failed.' &&
      typeof window === 'object'
    ) {
      window.location.href = routes.authentication.logout;
    }

    return Promise.reject(error);
  }
);
