/**
 * Module dependencies.
 */

import { CompleteSignUpBanner } from './banners/complete-sign-up';
import { Dropdown } from 'src/components/core/dropdown';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { ValidatingBanner } from './banners/validating';
import { media } from '@untile/react-core/styles/media';
import { routes } from 'src/core/constants/routes';
import { useCreateSubmission } from 'src/api/me/submission';
import { useElementDimensions } from 'src/hooks/use-element-dimensions';
import { useLogout } from 'src/api/authentication/logout';
import { useRouter } from 'next/router';
import { useSession } from 'src/providers/session';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { useEffect, useMemo } from 'react';
import brpLogoSvg from 'src/assets/brp-logo.svg';
import citySvg from 'src/assets/24/city.svg';
import dotsSvg from 'src/assets/24/dots.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  hideBanner?: boolean;
};

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: var(--z-index-navbar);
`;

/**
 * `NavContent` styled component.
 */

const NavContent = styled.div`
  align-items: center;
  background-color: var(--color-blue500);
  color: var(--color-white);
  display: flex;
  gap: 16px;
  height: var(--navbar-height);
  justify-content: space-between;
  padding: var(--gutter);
`;

/**
 * `Logo` styled component.
 */

const Logo = styled(Svg).attrs({
  icon: brpLogoSvg,
  size: '96px'
})`
  width: var(--navbar-height);
  ${media.min.sm`
    width: 122px;
  `}
`;

/**
 * `Profile` styled component.
 */

const Profile = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`;

/**
 * `UserInfoWrapper` styled component.
 */

const UserInfoWrapper = styled(RouterLink)`
  align-items: center;
  display: flex;
  transition: color var(--transition-default);

  ${media.max.ms`
    display: none;
  `}

  &:focus,
  &:hover {
    color: var(--color-grey300);
  }
`;

/**
 * `UserInfo` styled component.
 */

const UserInfo = styled.div`
  margin-left: 16px;

  span {
    display: block;
  }
`;

/**
 * `DotsWrapper` styled component.
 */

const DotsWrapper = styled.a`
  align-content: center;
  cursor: pointer;
  display: flex;
  transition: color var(--transition-default);

  &:focus,
  &:hover {
    color: var(--color-grey300);
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ variant: 'paragraph2' })`
  font-weight: 700;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.max.ms`
    max-width: 140px;
  `}
`;

/**
 * Export `Navbar` component.
 */

export function Navbar({ hideBanner = false }: Props) {
  const { t } = useTranslation();
  const { user } = useSession();
  const [ref, { height }] = useElementDimensions<HTMLDivElement>();
  const { mutate: createSubmission } = useCreateSubmission();
  const router = useRouter();
  const { mutate } = useLogout();
  const menuOptions = useMemo(
    () => [
      {
        label: t('common:navbar.options.newSurvey'),
        onClick: () => {
          createSubmission();
        }
      },
      {
        disabled: router.asPath === routes.settings.profile,
        label: t('common:navbar.options.settings'),
        onClick: () => {
          router.push(routes.settings.profile);
        }
      },
      {
        label: t('common:navbar.options.logout'),
        onClick: () => {
          mutate();
        }
      }
    ],
    [createSubmission, mutate, router, t]
  );

  useEffect(() => {
    if (height) {
      document.documentElement.style.setProperty(
        '--navbar-total-height',
        `${height}px`
      );
    }

    return () => {
      document.documentElement.style.setProperty(
        '--navbar-total-height',
        '0px'
      );
    };
  }, [height]);

  return (
    <>
      <Nav ref={ref}>
        <NavContent>
          <Link href={routes.dashboard}>
            <Logo />
          </Link>

          <Profile>
            <UserInfoWrapper href={routes.settings.profile}>
              <Svg icon={citySvg} size={'24px'} />

              <UserInfo>
                <Title>{user?.companyName ?? user?.email}</Title>

                <Text variant={'caption'}>
                  {user?.nif.replace(/.{3}/g, '$& ')}
                </Text>
              </UserInfo>
            </UserInfoWrapper>

            <Dropdown id={'navbar-dropdown'} options={menuOptions}>
              {props => (
                <DotsWrapper {...props}>
                  <Svg icon={dotsSvg} size={'32px'} />
                </DotsWrapper>
              )}
            </Dropdown>
          </Profile>
        </NavContent>

        {user?.status === 'incomplete' && !hideBanner && (
          <CompleteSignUpBanner />
        )}

        {user?.status === 'pending' && !hideBanner && <ValidatingBanner />}
      </Nav>
    </>
  );
}
