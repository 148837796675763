/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { colors } from 'src/styles/colors';
import { ifProp, switchProp } from 'styled-tools';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  as?: 'a' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small' | 'strong';
  block?: boolean;
  children: ReactNode;
  color?: keyof typeof colors;
  fontWeight?: 400 | 600 | 700 | 800;
  variant?: keyof typeof textStyles;
};

/**
 * Export `Text` component.
 */

export const Text = styled.span<Props>`
  ${switchProp('variant', textStyles, textStyles.paragraph1)}
  ${ifProp('block', 'display: block;')}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}; `}
  ${({ color }) => color && `color: var(--color-${color});`}

  margin: 0;
`;
