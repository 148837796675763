/**
 * Module dependencies.
 */

import { AuthenticationLayout } from './authentication';
import { BaseLayout } from './base';
import { CompleteSignUpLayout } from './complete-sign-up';
import { LayoutType } from 'src/types/app';
import { PageTransition } from './page-transition';
import { ReactNode } from 'react';
import { SettingsLayout } from './settings';
import { SurveyLayout } from './survey';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode;
  layout?: LayoutType;
};

/**
 * `layouts` constant.
 */

const layouts = {
  auth: AuthenticationLayout,
  base: BaseLayout,
  completeSignUp: CompleteSignUpLayout,
  settings: SettingsLayout,
  survey: SurveyLayout
} as const;

/**
 * Export `Layout` component.
 */

export function Layout({ children, layout = 'base' }: Props) {
  const Layout = layouts?.[layout] ?? layouts.base;

  return (
    <PageTransition>
      <Layout>{children}</Layout>
    </PageTransition>
  );
}
