/**
 * Module dependencies
 */

import { css } from 'styled-components';
import { media } from '@untile/react-core/styles/media';

/**
 * `display1`.
 */

const display1 = css`
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;

  ${media.min.sm`
    font-size: 64px;
    line-height: 80px;
  `}
`;

/**
 * `display2`.
 */

const display2 = css`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;

  ${media.min.sm`
    font-size: 52px;
    line-height: 64px;
  `}
`;

/**
 * `display3`.
 */

const display3 = css`
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;

  ${media.min.sm`
    font-size: 36px;
    line-height: 48px;
  `}
`;

/**
 * `h1`.
 */

const h1 = css`
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;

  ${media.min.sm`
    font-size: 28px;
    line-height: 40px;
  `}
`;

/**
 * `h2`.
 */

const h2 = css`
  font-size: 20px;
  line-height: 28px;

  ${media.min.sm`
    font-size: 22px;
    line-height: 32px;
  `}
`;

/**
 * `h3`.
 */

const h3 = css`
  font-size: 16px;
  line-height: 20px;

  ${media.min.sm`
    font-size: 18px;
    line-height: 24px;
  `}
`;

/**
 * `paragraph1`.
 */

const paragraph1 = css`
  font-size: 14px;
  line-height: 20px;

  ${media.min.sm`
    font-size: var(--default-font-size);
    line-height: var(--default-line-height);
  `}
`;

/**
 * `paragraph2`.
 */

const paragraph2 = css`
  font-size: 12px;
  line-height: 16px;

  ${media.min.sm`
    font-size: 14px;
    line-height: 22px;
  `}
`;

/**
 * `paragraph3`.
 */

const paragraph3 = css`
  font-size: 11px;
  letter-spacing: 0.1px;
  line-height: 14px;

  ${media.min.sm`
    font-size: 12px;
    line-height: 18px;
  `}
`;

/**
 * `label1`.
 */

const label1 = css`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 22px;
`;

/**
 * `label2`.
 */

const label2 = css`
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 16px;
`;

/**
 * `caption`.
 */

const caption = css`
  font-size: 9px;
  letter-spacing: 0.2px;
  line-height: 16px;

  ${media.min.sm`
    font-size: 10px;
  `}
`;

/**
 * Export `textStyles`.
 */

export const textStyles = {
  caption,
  display1,
  display2,
  display3,
  h1,
  h2,
  h3,
  label1,
  label2,
  paragraph1,
  paragraph2,
  paragraph3
} as const;
