/**
 * Module dependencies.
 */

import {
  GetPropsCommonOptions,
  UseSelectGetToggleButtonPropsOptions,
  useSelect
} from 'downshift';

import { Text } from 'src/components/core/text';
import { Transition, TransitionStatus } from 'react-transition-group';
import { ifProp, prop, switchProp } from 'styled-tools';
import React, { ReactNode, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

/**
 * `Option` type.
 */

type Option = {
  disabled?: boolean;
  label: string;
} & (
  | {
      onClick: () => void;
    }
  | {
      value: string;
    }
);

/**
 * `RenderOptionProps` type.
 */

type RenderOptionProps = {
  index: number;
  isHighlighted: boolean;
  isSelected: boolean;
  option: Option;
};

/**
 * Export `DropdownProps` type.
 */

export type DropdownProps = {
  children: (props: {
    options?: UseSelectGetToggleButtonPropsOptions;
    otherOptions?: GetPropsCommonOptions;
    selectedOption?: Option;
  }) => ReactNode;
  className?: string;
  id: string;
  options: Option[];
  placement?: 'bottom' | 'top';
  renderOption?: (props: RenderOptionProps) => ReactNode;
  width?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;
`;

/**
 * `List` styled component.
 */

const List = styled.ul<
  Pick<DropdownProps, 'placement' | 'width'> & {
    transitionStatus: TransitionStatus;
  }
>`
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  background-color: var(--color-white);
  border: 1px solid var(--color-blue500);
  border-radius: 8px;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr;
  max-height: 215px;
  opacity: 0;
  overflow-y: auto;
  padding: 4px;
  position: absolute;
  right: 0;
  width: ${prop('width', 'max-content')};
  z-index: var(--z-index-dropdown);

  ${switchProp('placement', {
    bottom: 'top: calc(100% + 4px);',
    top: 'bottom: 4px;'
  })}

  ${switchProp('transitionStatus', {
    entered: `
      animation-name: fadeInUp;
    `,
    entering: `
      animation-name: fadeInUp;
    `,
    exited: 'opacity: 0;',
    exiting: 'opacity: 0;'
  })}
`;

/**
 * Export `DropdownItem` styled component.
 */

export const DropdownItem = styled.button<{
  isHighlighted?: boolean;
  isSelected: boolean;
}>`
  appearance: none;
  background-color: var(--color-white);
  border-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  padding: 12px 16px;
  position: relative;
  text-align: left;
  transition: var(--transition-default);
  transition-property: background-color, color;
  width: 100%;

  :focus,
  :focus-within,
  :hover {
    background-color: var(--color-grey100);
  }

  ${ifProp('isHighlighted', 'background-color: var(--color-grey100);')}
  ${ifProp(
    'isSelected',
    `
    background-color: var(--color-blue100);
    cursor: default;
    pointer-events: none;
  `
  )}

  ${ifProp(
    'disabled',
    `
    background-color: var(--color-grey100);
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  `
  )}
`;

/**
 * `defaultRenderOption` util.
 */

function defaultRenderOption({
  isHighlighted,
  isSelected,
  option
}: RenderOptionProps) {
  const { disabled, label, onClick } = option as any;

  return (
    <DropdownItem
      as={!onClick ? 'div' : (undefined as any)}
      disabled={disabled}
      isHighlighted={isHighlighted}
      isSelected={!onClick ? isSelected : undefined}
      onClick={onClick}
    >
      <Text style={{ color: 'var(--text-color)' }} variant={'paragraph2'}>
        {label}
      </Text>
    </DropdownItem>
  );
}

/**
 * Export `Dropdown` component.
 */

export function Dropdown(props: DropdownProps) {
  const {
    children,
    className,
    id,
    options,
    placement = 'bottom',
    renderOption = defaultRenderOption,
    width
  } = props;

  const nodeRef = useRef<HTMLUListElement>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const selectedOption = useMemo(
    () => options?.find(option => (option as any)?.value === selectedValue),
    [options, selectedValue]
  );

  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen
  } = useSelect({
    id,
    items: options,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (newSelectedItem?.value) {
        setSelectedValue(newSelectedItem?.value);
      }
    },
    selectedItem: selectedValue
  });

  return (
    <Wrapper className={className}>
      {children({ ...getToggleButtonProps(), selectedOption })}

      <div {...getMenuProps()}>
        <Transition
          in={isOpen}
          mountOnEnter
          nodeRef={nodeRef}
          timeout={500}
          unmountOnExit
        >
          {(state: TransitionStatus) => (
            <List
              placement={placement}
              ref={nodeRef}
              transitionStatus={state}
              width={width}
            >
              {options.map((option, index) => (
                <li key={index} {...getItemProps({ index, item: option })}>
                  {renderOption({
                    index,
                    isHighlighted: highlightedIndex === index,
                    isSelected: selectedValue === (option as any)?.value,
                    option
                  })}
                </li>
              ))}
            </List>
          )}
        </Transition>
      </div>
    </Wrapper>
  );
}
