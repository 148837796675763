/**
 * Module dependencies.
 */

import { BackLink, StickyContent, stickyId } from './sticky';
import { Container } from 'src/components/core/container';
import { Footer } from './footer';
import { Grid } from './survey';
import { Navbar } from './navbar';
import { ReactNode } from 'react';
import { Svg } from '@untile/react-core/components/svg';
import { routes } from 'src/core/constants/routes';
import { useTranslation } from 'next-i18next';
import arrowLeftSvg from 'src/assets/24/circle-left.svg';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `CompleteSignUpLayout` component.
 */

export function CompleteSignUpLayout({ children }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Navbar hideBanner />

      <Container>
        <Grid>
          <StickyContent style={{ backgroundColor: 'var(--color-grey100)' }}>
            <BackLink href={routes.dashboard}>
              <Svg icon={arrowLeftSvg} size={'24px'} />

              {t('common:actions.back')}
            </BackLink>

            {!(children as any)?.props?.hideLayoutSticky && (
              <div id={stickyId} />
            )}
          </StickyContent>

          {children}

          <div style={{ gridColumn: '1 / -1', gridRow: 5 }}>
            <Footer />
          </div>
        </Grid>
      </Container>
    </>
  );
}
