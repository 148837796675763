/**
 * Module dependencies.
 */

import { AverageScoresParams } from 'src/types/answer';

/**
 * Export `queryKeys` constant.
 */

export const queryKeys = {
  answers: {
    averageScores: (params: AverageScoresParams) => [
      'answers',
      'averageScores',
      params
    ]
  },
  expectedScores: ['expectedScores'],
  me: ['session', 'me'],
  questions: (isFamilyCompany: boolean) =>
    isFamilyCompany ? ['questions'] : ['nonFamilyCompanyQuestions'],
  questionsTotalScores: (isFamilyCompany: boolean) => [
    'scores',
    'byCategory',
    ...(isFamilyCompany ? ['familyCompany'] : ['nonFamilyCompany'])
  ],
  settingsOptions: ['settingsOptions'],
  submission: {
    byId: (id: string) => ['session', 'submissions', id],
    infinite: ['session', 'submissions', 'infinite'],
    previous: (id: string) => ['session', 'submissions', 'previous', id],
    submittedList: ['session', 'submissions', 'submittedList']
  }
};
